import * as React from "react";

// CSS
import "./src/globalStyles/global.css";

// Wraps every page in the Layout to avoid dismount
import { PageLayout } from "./src/components/layout/layout";

export const wrapPageElement = ({ element, props }) => {
  return <PageLayout {...props}>{element}</PageLayout>;
};
