import { createContext } from "react";
export const DataContext = createContext({
  lang: true,
  cartTotal: 0,
  QVopen: false,
  QVSKU: "",
  lifted: false,
  page: "index",
  updateCart: false,
  totalInCart: 0,
  changeVehicleDrawer: false,
  vehicleName: "---",
  hour: 0,
  day: 1,
  userData: {},
  showSkewedPrices: false,
  showDiscountBand: false,
  showSkewedOldPrices: false,
  percentageOff: 0.9,
  percentageOffText: "10",
  date: "0",
});
