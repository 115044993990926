import React, { useContext } from "react";
import { Link } from "gatsby";

// CONTEXT
import { DataContext } from "../../../context/dataContext/dataContext";

// MUI
import { Container } from "@mui/system";

import { FBmessenger } from "../../facebook/insert/fb";

// CSS
import * as StyledComponents from "./footer.module.scss";

// MARKUP
export const Footer = () => {
  //CONTEXT
  const [DataValue] = useContext(DataContext);
  return (
    <footer className={StyledComponents.footer}>
      <FBmessenger />

      <div className={StyledComponents.blackbar}>
        <Container maxWidth="xl">
          {/* TOP FOOTER */}
          <div className={StyledComponents.topfooter}>
            <Link to="/">
              <p>{DataValue.lang ? "Accueil" : "Home"}</p>
            </Link>
            <a href="https://www.facebook.com/CanadaOffroad">
              <p>Facebook</p>
            </a>
            <a href="https://www.instagram.com/canadaoffroadonline">
              <p>Instagram</p>
            </a>
            <Link to="/contact">
              <p>{DataValue.lang ? "Contactez-nous" : "Contact us"}</p>
            </Link>
            <Link to="/returns">
              <p>
                {DataValue.lang
                  ? "Annulations et retour"
                  : "Cancellations and returns"}
              </p>
            </Link>
          </div>
        </Container>
      </div>
      {/* OPINION */}
      <Container maxWidth="xl">
        <div className={StyledComponents.opinion}>
          <p className={StyledComponents.title}>
            {DataValue.lang ? "Votre opinion compte!" : "Your opinion counts!"}
          </p>
          <p>
            {DataValue.lang
              ? "Nous essayons toujours de nous améliorer et vous pouvez nous aider avec vos commentaires."
              : "We are always trying to improve and you can help us with your feedback."}
          </p>
          <a href="mailto:info@canadaoffroad.com">
            <p>info@canadaoffroad.com</p>
          </a>
          <p>
            <a href="tel:+18333332992">+1 833-333-2992</a> Français / English
          </p>
        </div>
      </Container>
      {/* COPY RIGHT */}
      <div className={StyledComponents.trademark}>
        <p>© 2023 CANADA OFFROAD</p>
      </div>
    </footer>
  );
};
