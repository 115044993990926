import * as React from "react";

// MARKUP
export const FBmessenger = () => {
  function fbinit() {
    /*global FB*/
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      //  Your SDK code
      window.fbAsyncInit = function () {
        FB.init({
          xfbml: true,
          version: "v16.0",
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/fr/sdk/xfbml.customerchat.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
      return (
        <>
          <div id="fb-root"></div>
          <div
            id="fb-customer-chat"
            class="fb-customerchat"
            page_id="101193609163605"
            attribution="biz_inbox"
            greeting_dialog_display="hide"
          ></div>
        </>
      );
    }
  }

  return fbinit();
};
