import React, { useEffect } from "react";
import { Script } from "gatsby";

// GLOBAL CONTEXT
import { DataGlobalContext } from "../../context/dataContext/dataGlobalContext";

// MUI IMPORT
import { createTheme, ThemeProvider } from "@mui/material/styles";

// HELMET IMPORT
import { Helmet } from "react-helmet";

// SENTRY IMPORT
import * as Sentry from "@sentry/browser";
// import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { CaptureConsole } from "@sentry/integrations";

// COMPONENTS
import { NavBar } from "./navbar/navbar";
import { Footer } from "./footer/footer";

// FUNCTIONS
import { updateVersion } from "../../functions/updateVersion";

// CSS

// ******************************************************************************************

// SENTRY CONFIG
Sentry.init({
  dsn: "https://dd8e9973baf04d68be088d7825b91c9d@o1212710.ingest.sentry.io/6350927",
  denyUrls: [
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    "localhost:8000",
    "netlify.app",
  ],
  ignoreErrors: [
    /^Error retrieving login status/,
    "Sentry Logger [Warn]: The SDK was initialized in the Sentry config file, but options were found in the Gatsby config. These have been ignored, merge them to the Sentry config if you want to use them.\nLearn more about the Gatsby SDK on https://docs.sentry.io/platforms/javascript/guides/gatsby/",
    "Error during service worker registration: Error: Rejected",
    "Content is now available offline!",
    "installingWorker [object ServiceWorker]",
    "The installing service worker became redundant.",
    "Error during service worker registration",
  ],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0,
  integrations: [
    // new CaptureConsoleIntegration(),
    new CaptureConsole({
      levels: ["error"],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new Sentry.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["canadaoffroad.com"],
});

// MUI CONFIG
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

//MARKUP
export const PageLayout = ({ children }) => {
  useEffect(() => {
    // GET VERSION
    updateVersion("04142024");
  }, []);

  return (
    <DataGlobalContext>
      {/* GOOGLE ANALYTICS aka Google tag (gtag.js)*/}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KZXDCXGZWW"
      ></Script>
      <Script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KZXDCXGZWW');`}
      </Script>

      <Script
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-KZXDCXGZWW"
        strategy="off-main-thread"
      ></Script>
      <Script
        defer
        id="gtag-config"
        strategy="off-main-thread"
        forward={[`gtag`]}
      >
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-KZXDCXGZWW'), { page_path: location ? location.pathname + location.search + location.hash : undefined });`}
      </Script>

      <Helmet>
        {/* FACEBOOK SDK */}
        <script
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/fr_CA/sdk.js#xfbml=1&version=v14.0&appId=405617350941669&autoLogAppEvents=1"
          nonce="vj8F75HK"
        ></script>
        <meta
          name="facebook-domain-verification"
          content="cj9ib4tv3kddiyfiaw6nelbhvviiw7"
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        <NavBar />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </DataGlobalContext>
  );
};
